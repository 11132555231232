import React, { useState, useEffect } from "react";
import BadgeForm from "./BadgeForm";
import Navbar from "./Navbar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as badgeActions from "../redux/actions/badgeActions";
import { RiDeleteBin6Line } from "react-icons/ri";
import Avatar from "react-avatar";
import badgeImg from "../assest/badge.jpg";
import { toast } from "react-toastify";
import { Card, Col, Table, Image, Spinner } from "react-bootstrap";

function BadgeList(props) {
  let tracker_id = props.match?.params?.tracker_id;

  const [badge, setBadge] = useState([]);
  const [showBadgeCreationDialog, setShowBadgeCreationDialog] = useState(null);

  useEffect(() => {
    getAllBadge();
  }, []);

  const getAllBadge = () => {
    props.badgeActions.getAllBadgesByTracker(tracker_id, (data) => {
      if (data !== "error") {
        setBadge(data.reverse());
      } else {
        console.log("fetching error");
      }
    });
  };

  const addBadge = (todo) => {
    if (!todo.badgeName || /^\s*$/.test(todo.badgeName)) {
      return;
    }

    props.badgeActions.addBadge({ ...todo, tracker_id: tracker_id }, (data) => {
      if (data !== "error") {
        setShowBadgeCreationDialog(false);

        toast.success("Badge added successfully");
        getAllBadge();
      } else {
        toast.error("something went wrong");
      }
    });
  };

  const removeTodo = (id) => {
    props.badgeActions.deleteBadge(id, (resp) => {
      console.log(resp);
      toast.success("deleted successfully");
      getAllBadge();
    });
  };

  return (
    <>
      <Navbar {...props} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          margin: "20px 0px",
        }}
      >
        <Col md={6}>
          <Card>
            <Card.Body>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "20px",
                }}
              >
                <h4>Create Badges</h4>
                <BadgeForm show={showBadgeCreationDialog} onSubmit={addBadge} />
                {!badge && (
                  <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
                {badge && badge.length > 0 ? (
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th className="align-middle text-center">Badge Logo</th>
                        <th className="align-middle text-center">Badge Name</th>
                        <th className="align-middle text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {badge.map((b) => (
                        <tr>
                          <td className="align-middle text-center">
                            <Image
                              style={{ height: "100px", width: "100px" }}
                              src={`${b.graphics}`}
                              alt={b.altDescription}
                              title={b.altDescription}
                              roundedCircle
                            />
                          </td>
                          <td className="align-middle text-center">
                            {b.badgeName}
                          </td>
                          <td className="align-middle text-center">
                            <RiDeleteBin6Line
                              onClick={() => removeTodo(b.id)}
                              className="delete-icon"
                              style={{ cursor: "pointer" }}
                              color="red"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-center fs-5">
                    <i
                      className="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                    No badges have been created. Please add a new badge.
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    allBadges: state.badge.allBadges,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    badgeActions: bindActionCreators(badgeActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BadgeList);
