import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Login from "./component/Login";
import SignUp from "./component/SignUp";
import BadgeList from "./component/BadgeList";
import Dashboard from "./component/Dashboard";
// import Userdash from "./component/User_dash";
// import UserGroup from "./component/User_group";

// import User from "./component/User";
import TrackerHome from "./Tracker/TrackerHome";

import Verification from "./component/Verification";
import "./App.css";
import "./Navbar.css";
import "./Badge.css";

function App() {
  const token = localStorage.getItem("token");
  const isremember = localStorage.getItem("isremember");

  return (
    <>
      <ToastContainer autoClose={3000} />
      <Router>
        <Switch>
          <Route
            path="/"
            exact
            render={(props) =>
              !token && !isremember ? (
                <Login {...props} />
              ) : (
                <Redirect to="/dashboard" />
              )
            }
          />
          <Route
            path="/register"
            render={(props) =>
              !token && !isremember ? (
                <SignUp {...props} />
              ) : (
                <Redirect to="/dashboard" />
              )
            }
          />

          {/* <Route
            path="/user"
            render={(props) =>
              token ? <Userdash {...props} /> : <Redirect to="/" />
            }
          /> */}

          <Route
            path="/dashboard"
            render={(props) =>
              token ? <Dashboard {...props} /> : <Redirect to="/" />
            }
          />

          <Route
            path="/add/badge/:tracker_id"
            render={(props) =>
              token ? <BadgeList {...props} /> : <Redirect to="/" />
            }
          />

          <Route
            path="/tracker/:tracker_id"
            render={(props) =>
              token ? <TrackerHome {...props} /> : <Redirect to="/" />
            }
          />

          {/* <Route
            path="/user_group/:tracker_id"
            render={(props) =>
              token ? <User {...props} /> : <Redirect to="/" />
            }
          /> */}

          <Route
            path="/group/invite"
            render={(props) => <Verification {...props} />}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
//  tracker list
//  on click tracker list color wala format
//  grup name and badge kitna milega
