import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../constants";
import "../login.css";
import { toast } from "react-toastify";
import logo from "../assest/logo.png";

function Login() {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [check, setCheck] = useState(false);
  // const [inputs, setInputs] = useState({
  //   userid: "",
  //   password: "",
  //   rememberPassword: false,
  // });
  // const { userid, rememberPassword } = inputs;
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function nextStep(e) {
    e.preventDefault();
    let data = { email };
    if (email) {
      axios({
        method: "post",
        url: `${baseUrl}/find`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then((res) => {
          console.log(res.data.message);
          setStep((cur) => cur + 1);
        })
        .catch((err) => {
          console.log(err);
          toast.error("This Email ID is not registered with us");
        });
    } else {
      toast.warn("Please Enter your email");
    }
  }

  const signIn = (e) => {
    e.preventDefault();

    if (password) {
      let data = {
        email: email,
        password: password,
      };
      try {
        console.log("api called");
        axios({
          method: "post",
          url: `${baseUrl}/login`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then((response) => {
            console.log("====>responseLogin", response);
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.data));

            localStorage.setItem("isremember", JSON.stringify(check));

            const user = JSON.parse(localStorage.getItem("user"));
            console.log(user.isTracker);
            // { user.isTracker ? history.push("/dashboard") : history.push("/user")};
            // if (user.isTracker) {
            history.push("/dashboard");
            // }
            // else {
            //   history.push("/user")
            // }
            window.location.reload();
            toast.success("Logged in successfully");
          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.data.message);
              // return;
            }
            console.log(error);
          });
      } catch (e) {
        console.log("errorCatch", e);
      }
    } else {
      toast.warn("Please enter password");
    }
  };

  return (
    <>
      <div className="center">
        <div className="form" id="form1">
          {/* <h2>BadgeTracker</h2> */}
          <img src={logo} style={{ width: "30%", paddingBottom: "30px" }} />
          {step === 0 && (
            <>
              <Form onSubmit={nextStep} className="email">
                <Form.Group size="lg" controlId="email">
                  <Form.Control
                    style={{ marginBottom: "30px", fontSize: "15px" }}
                    autoFocus
                    placeholder="Email Address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Form>
              <div className="d-grid gap-2">
                <Button variant="primary" size="sm" onClick={nextStep}>
                  {" "}
                  Login{" "}
                </Button>
              </div>
              <hr />
              <p>or</p>
              <Button variant="secondary" href="/register">
                Sign up
              </Button>
            </>
          )}
          {step === 1 && (
            <>
              <Form onSubmit={signIn} className="password">
                <Form.Group size="lg" controlId="password">
                  <Form.Control
                    style={{
                      padding: "10px 130px 10px 30px",
                      marginBottom: "30px",
                      fontSize: "15px",
                    }}
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                {/*
                  remember option doesn't work properly
                  so disable it for now
                 */}
                {/* <div className="form-check">
                  <label className="form-check-label"> Remember me </label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rememberPassword"
                    name="checkbox"
                    checked={check}
                    onChange={(e) => setCheck(e.target.checked)}
                  />
                </div> */}
              </Form>

              <div className="d-grid gap-2">
                <Button
                  variant="primary"
                  size="sm"
                  disabled={!validateForm()}
                  onClick={signIn}
                >
                  Login
                </Button>
              </div>
              <br />
              <br />
              <Link to="/register">Don't have account? Register Now</Link>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default Login;
