import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Form,
  Container,
  Row,
  Col,
  Button,
  Accordion,
  InputGroup,
  FormControl,
  Table,
  Modal,
  Image,
  Tabs,
  Tab,
  Collapse,
} from "react-bootstrap";
import Navbar from "../component/Navbar";
import { FiSettings } from "react-icons/fi";
import { GiPoliceBadge } from "react-icons/gi";
import { baseUrl } from "../constants";
import * as groupActions from "../redux/actions/groupAction";
import * as trackerAction from "../redux/actions/trackerActions";
import * as badgeActions from "../redux/actions/badgeActions";
import "../tracker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import Calendar from "react-calendar";
import "./TrackerHome.scss";

function TrackerHome(props) {
  let tracker_id = props.match?.params?.tracker_id;

  function Set() {
    return { __html: props.trackerInfo?.description };
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const [userEmail, setUserEmail] = useState("");
  const [groupName, setGroupName] = useState("");
  const [badgeAllotmentFrequency, setBadgeAllotmentFrequency] =
    useState("daily");
  const [cal, setCal] = useState(false);
  const [trackerModalShow, setTrackerModalShow] = React.useState(false);
  const [groupModalShow, setGroupModalShow] = React.useState(false);
  const [userModalShow, setUserModalShow] = React.useState(false);
  const [siteName, setSiteName] = useState("");
  const [banner, setBanner] = useState("");
  const [background, setBackground] = useState("");
  const [widget, setWidget] = useState("");
  const [logo, setLogo] = useState("");
  const [description, setDescription] = useState("");
  const [usersOfGroup, setUsersOfGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [redBadges, setRedeemedBadges] = useState(0);
  const [badgesOfGrp, setBadgesOfGrp] = useState([]);
  const [listOfAllocate, setListofAllocate] = useState([]);
  const [note, setNote] = useState("");
  const [open, setOpen] = useState(null);
  const [notes, setNotes] = useState([]);
  const [allocate, setAllocate] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);

  const defaultTab = user?.isStudent ? "User" : "Tracker";

  const findBadgeByUserDate = (user_id, date) => {
    props.badgeActions.findAllocateByUserDate(user_id, date, (data) => {
      setAllocate(data.map((item) => item.badge_id));
    });
  };

  useEffect(() => {
    props.trackerActions.getTrackers(tracker_id);
    findBadgeByUserDate(user.id, selectedDate);
  }, [tracker_id]);

  const createGroup = () => {
    const data = {
      groupName,
      badgeAllotmentFrequency: "daily",
      tracker_id,
    };

    props.groupActions.addGroupForTracker(data, (res) => {
      if (res !== "error") {
        toast.success("Group saved successfully");
      } else {
        toast.error("something went wrong");
      }
      props.trackerActions.getTrackers(tracker_id);
    });
  };

  const editGroup = (id) => {
    props.groupActions.editGroup(
      id,
      { groupName, badgeAllotmentFrequency: "daily" },
      (res) => {
        if (res !== "error") {
          toast.success("Group updated successfully");
        } else {
          toast.error("something went wrong");
        }
        props.trackerActions.getTrackers(tracker_id);
      }
    );
  };

  const deleteGroup = (id) => {
    props.groupActions.deleteGroup(
      id,

      (res) => {
        if (res !== "error") {
          toast.warn("Group deleted successfully");
        } else {
          toast.error("something went wrong");
        }
        props.trackerActions.getTrackers(tracker_id);
      }
    );
  };

  const fetchTrackerDetails = () => {
    setTrackerModalShow(true);
    setSiteName(props.trackerInfo?.siteName);
    setDescription(props.trackerInfo?.description);
    setLogo(props.trackerInfo?.logo);
    setBanner(props.trackerInfo?.bannerColor);
    setBackground(props.trackerInfo?.backgroundColor);
    setWidget(props.trackerInfo?.widgetColor);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      siteName: siteName,
      siteLogo: logo,
      description: description,
      bannerColor: banner,
      backgroundColor: background,
      widgetColor: widget,
      user_id: user.id,
    };
    props.trackerActions.editTracker(tracker_id, data, (data) => {
      if (data !== "error") {
        toast.success("tracker updated successfully");
        props.trackerActions.getTrackers(tracker_id);

        setTrackerModalShow(false);
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setLogo(base64);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleChange = (e, editor) => {
    setDescription(editor.getData());
  };

  const [addMemberResult, setAddMemberResult] = useState([]);
  useEffect(() => {
    !groupModalShow && setUserEmail("");
  }, [groupModalShow]);

  const addMember = (group_id) => {
    if (userEmail) {
      let member_data = {
        group_id: group_id,
        email: userEmail,
      };
      setAddMemberResult(null);

      axios({
        method: "post",
        url: `${baseUrl}/member/add`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: member_data,
      })
        .then((res) => {
          const result = res.data.data;
          setAddMemberResult(result);

          if (
            result.every(
              (t) => t.status === "invited" || t.status === "accepted"
            )
          ) {
            toast.success("Invited successfully");
            setUserEmail("");
            return;
          }

          const uninvitedEmails = [];
          res.data.data.forEach((member) => {
            if (member.status === null) {
              uninvitedEmails.push(member.email);
            }
          });

          if (result.every((t) => !t.status)) {
            toast.error("Invitation failed");
          } else {
            toast.warn("Failed to invite some member");
          }

          setUserEmail(uninvitedEmails.join("; "));
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      toast.warn("Please Enter an email");
    }
  };
  const fetchUsersOfGroup = (group_id) => {
    axios({
      method: "GET",
      url: `${baseUrl}/member/getbygroup/${group_id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // axios({
        //   method: "GET",
        //   url: `${baseUrl}/member/getbygroup/${group_id}`,
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //   },
        // }).then((red) => {
        //   console.log({})
        // })

        setUsersOfGroup(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const deleteMember = (user_id, group_id) => {
    props.groupActions.deleteGroupMember(
      user_id,
      group_id,

      (res) => {
        if (res !== "error") {
          toast.warn("Group Member deleted successfully");
        } else {
          toast.error("something went wrong");
        }
        fetchUsersOfGroup(group_id);
      }
    );
  };

  const allocateBadge = (user_id, badge_id, group_id) => {
    props.badgeActions.allocateBadge(
      {
        user_id,
        badge_id,
        group_id,
        allocatedDate: moment(selectedDate).format("L"),
      },
      (data) => {
        if (data === "error") {
          toast.error("Something went wrong");

          return;
        }

        if (!data) {
          toast.error("Badge Removed!");
        } else {
          toast.success("Badge Awarded!");
        }

        loadBadgesOfSelectedGroup();
      }
    );
  };

  const loadBadgesOfSelectedGroup = () => {
    props.badgeActions.findAllocatebyGroup(selectedGroup, null, (data) => {
      if (data === "error") {
        data = [];
      }
      setBadgesOfGrp(data);
    });
  };
  useEffect(() => {
    loadBadgesOfSelectedGroup();
  }, [selectedGroup]);

  const [badgesOfGroupOnSelectedDate, setBadgesOfGroupOnSelectedDate] =
    useState([]);
  useEffect(() => {
    const badges = badgesOfGrp.filter((b) => {
      return b.allocatedDate === moment(selectedDate).format("Y-MM-DD");
    });
    setBadgesOfGroupOnSelectedDate(badges);
  }, [badgesOfGrp, selectedDate]);

  const countInArray = (array, what) => {
    return array.filter((item) => item === what).length;
  };
  const awardAll = (badge_id, group_id) => {
    usersOfGroup.forEach((u, index) => {
      allocateBadge(u.user_id, badge_id, group_id);
    });
  };

  // const fetchMyGroups = (type) => {
  //   console.log("-==-==DATATA")
  //   if (type === "User") {
  //     props.groupActions.fetchMyApprovedGroup(user?.id, (data) => {
  //       console.log(data);
  //       // setAllocate(data.map((item) => item.badge_id));
  //     });
  //   }
  // }

  const updateCalendarTileClassName = ({ activeStartDate, date, view }) => {
    if (!badgesOfGrp || !badgesOfGrp.length) {
      return;
    }

    const badgedDates = [];
    badgesOfGrp.forEach((b) => {
      badgedDates.push(b.allocatedDate);
    });

    const isOnList = badgedDates.some((bd, index) => {
      const inputDate = moment(date).format("Y-MM-DD");
      return inputDate === bd;
    });

    if (isOnList) {
      return "date-badged";
    } else {
      return null;
    }
  };

  const handleDateChanged = (date) => {
    setSelectedDate(moment(date).toDate());
  };

  const handleBadgeCheckbox = (event, userId, badgeId, groupId) => {
    event.preventDefault();

    if (user.status === "invited") {
      toast.error("User has not accepted invitation");

      return;
    }

    allocateBadge(userId, badgeId, groupId);
  };

  const handleUserClickInBadgeReward = (userId, groupId) => {
    setListofAllocate([]);
    setSelectedUser(userId);
    setUserModalShow(true);

    accumulateBadge(userId, groupId);
  };

  const handleUserClickInGroupInfo = (userId, groupId) => {
    setOpen(groupId);
    accumulateBadge(userId, groupId);

    props.badgeActions.findNotes(userId, (data) => {
      if (data !== "error") setNotes(data);
    });
  };

  const [balanceBadgesOfSelectedUser, setBalanceBadgesOfSelectedUser] =
    useState(0);
  useEffect(() => {
    const badges = badgesOfGrp.filter((b) => b.user_id === selectedUser);
    setBalanceBadgesOfSelectedUser(badges.length);
  }, [selectedUser, badgesOfGrp, redBadges]);

  const [numberOfBadgesToRedeem, setNumberOfBadgesToRedeem] = useState(0);
  useEffect(() => {
    if (
      numberOfBadgesToRedeem !== "" &&
      typeof Number(numberOfBadgesToRedeem) !== "number"
    ) {
      setNumberOfBadgesToRedeem(0);
    }

    if (Number(numberOfBadgesToRedeem) < 0) {
      setNumberOfBadgesToRedeem(0);
    }

    if (numberOfBadgesToRedeem > balanceBadgesOfSelectedUser) {
      setNumberOfBadgesToRedeem(balanceBadgesOfSelectedUser);
    }
  }, [numberOfBadgesToRedeem, balanceBadgesOfSelectedUser]);

  const handleRedeemBadge = (event, userId) => {
    event.preventDefault();

    props.badgeActions.redeemBadge(
      {
        user_id: userId,
        group_id: selectedGroup,
        count: numberOfBadgesToRedeem,
      },
      (res) => {
        setNumberOfBadgesToRedeem(0);
        loadBadgesOfSelectedGroup();
        accumulateBadge(userId, selectedGroup);
      }
    );
  };

  const [accumulatedBadges, setAccumulatedBadges] = useState({
    total: 0,
    redeemed: 0,
    balance: 0,
  });
  const accumulateBadge = (userId, groupId) => {
    const accumulated = {
      total: 0,
      redeemed: 0,
      balance: 0,
    };

    let allocatedBadges = [];
    let redeemdedBadges = null;

    props.badgeActions.findAllocateBadge(userId, groupId, (data) => {
      allocatedBadges = data.map((item) => item.badge_id);
      setListofAllocate(allocatedBadges);
      props.badgeActions.getRedeemedBadges(
        {
          user_id: userId,
          group_id: groupId,
        },
        (data) => {
          redeemdedBadges = data;
          setRedeemedBadges(data.count);

          const accumulates = allocatedBadges?.length || 0;
          const redeems = redeemdedBadges?.count || 0;

          accumulated.redeemed = redeems;
          accumulated.total = accumulates + redeems;
          accumulated.balance = accumulates;

          setAccumulatedBadges(accumulated);
        }
      );
    });
  };

  return (
    <>
      <Navbar color={props.trackerInfo?.bannerColor} />
      <div
        style={{
          backgroundColor: `${props.trackerInfo?.backgroundColor}`,
          minHeight: "100vh",
        }}
      >
        <Tabs
          defaultActiveKey={defaultTab}
          id="uncontrolled-tab-example"
          className="mb-3 px-5 pt-3 fs-5 border border-dark border-top-0"
          // onSelect={(k) => fetchMyGroups(k)}
        >
          <Tab eventKey="Tracker" title="MyTracker">
            {props.trackerInfo && props.trackerInfo.user_id !== user?.id
              ? "You don't have permission to edit this tracker"
              : ""}
            {props.trackerInfo && props.trackerInfo?.user_id === user?.id && (
              <div className="container">
                <div className="first_row">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="group"
                        style={{
                          backgroundColor: `${props.trackerInfo?.widgetColor}`,
                        }}
                      >
                        <h3>Create Group</h3>
                        <div className="inside">
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupName"
                            >
                              <Form.Label style={{ fontSize: "18px" }}>
                                Enter Group Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                                style={{
                                  border: "2px solid #d8d6d6",
                                }}
                              />
                            </Form.Group>
                          </Form>
                          <Button
                            className="btn pull-right mt-5"
                            onClick={createGroup}
                            disabled={
                              groupName === "" || badgeAllotmentFrequency === ""
                            }
                          >
                            Save Group
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="tool"
                        style={{
                          backgroundColor: `${props.trackerInfo?.widgetColor}`,
                        }}
                      >
                        <h3>Tracker Tools</h3>
                        <div className="tools">
                          <div className="setting">
                            <FiSettings
                              onClick={() => fetchTrackerDetails()}
                              style={{
                                fontSize: "90px",
                                marginTop: "20px",
                                border: "2px solid #cecece",
                                borderRadius: "10px",
                                padding: "0px 9px",
                              }}
                            />
                            <br />
                            <Modal
                              show={trackerModalShow}
                              onHide={() => setTrackerModalShow(false)}
                              size="lg"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                  Edit Tracker Site
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Form>
                                  <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formSiteName"
                                  >
                                    <Form.Label column="true" sm="2">
                                      Site Name
                                    </Form.Label>
                                    <Col sm="10">
                                      <Form.Control
                                        placeholder="Enter the site name"
                                        type="text"
                                        value={siteName}
                                        onChange={(e) =>
                                          setSiteName(e.target.value)
                                        }
                                      />
                                    </Col>
                                  </Form.Group>
                                  <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formPlaintextEmail"
                                  >
                                    <Form.Label column="true" sm="2">
                                      Description
                                    </Form.Label>
                                    <Col sm="10">
                                      <CKEditor
                                        data={description}
                                        editor={ClassicEditor}
                                        onChange={(e, editor) => {
                                          handleChange(e, editor);
                                        }}
                                      />
                                    </Col>
                                  </Form.Group>

                                  <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formSiteLogo"
                                  >
                                    <Form.Label column="true" sm="2">
                                      Site Logo
                                    </Form.Label>
                                    <Col sm="10">
                                      <Form.Control
                                        type="file"
                                        onChange={(e) => {
                                          uploadImage(e);
                                        }}
                                      />
                                    </Col>
                                  </Form.Group>
                                  <h4>Color Scheme:</h4>

                                  <Row>
                                    <Col sm={6}>
                                      <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formBannerColor"
                                      >
                                        <Form.Label column="true" sm="4">
                                          Banner
                                        </Form.Label>
                                        <Col sm="8">
                                          <Form.Control
                                            type="color"
                                            value={banner}
                                            onChange={(e) =>
                                              setBanner(e.target.value)
                                            }
                                          />
                                        </Col>
                                      </Form.Group>
                                      <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formBackgroundColor"
                                      >
                                        <Form.Label column="true" sm="4">
                                          Background
                                        </Form.Label>
                                        <Col sm="8">
                                          <Form.Control
                                            type="color"
                                            value={background}
                                            onChange={(e) =>
                                              setBackground(e.target.value)
                                            }
                                          />
                                        </Col>
                                      </Form.Group>
                                      <Form.Group
                                        as={Row}
                                        className="mb-3"
                                        controlId="formWidgetColor"
                                      >
                                        <Form.Label column="true" sm="4">
                                          Widget
                                        </Form.Label>
                                        <Col sm="8">
                                          <Form.Control
                                            type="color"
                                            value={widget}
                                            onChange={(e) =>
                                              setWidget(e.target.value)
                                            }
                                          />
                                        </Col>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                      <div
                                        className="w-100 h-100"
                                        style={{
                                          border: "2px solid black",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div
                                          className="mb-0"
                                          style={{
                                            backgroundColor: `${banner}`,
                                            minHeight: "25%",
                                          }}
                                        >
                                          <p className="text-center">Banner</p>
                                        </div>
                                        <div
                                          // className="h-75"
                                          style={{
                                            backgroundColor: `${background}`,
                                            minHeight: "75%",
                                          }}
                                        >
                                          <span className="h-25">
                                            Background
                                          </span>
                                          <div
                                            className="w-50 h-75 mt-2"
                                            style={{
                                              backgroundColor: `${widget}`,
                                            }}
                                          >
                                            <p className="text-center my-auto">
                                              Widget
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Form>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  type="submit"
                                  // className="d-block m-auto mb-6 py-2 px-4"
                                  // size="lg"
                                  variant="primary"
                                  onClick={handleSubmit}
                                >
                                  <i
                                    className="fa fa-pencil-square-o m-1"
                                    aria-hidden="true"
                                  ></i>
                                  Update Tracker
                                </Button>

                                <Button
                                  onClick={() => setTrackerModalShow(false)}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                            <a
                              onClick={() => fetchTrackerDetails()}
                              style={{ cursor: "pointer" }}
                            >
                              Settings
                            </a>
                          </div>
                          <div className="add">
                            <GiPoliceBadge
                              onClick={() =>
                                props.history.push(`/add/badge/${tracker_id}`)
                              }
                              style={{
                                cursor: "pointer",
                                fontSize: "90px",
                                marginTop: "20px",
                                border: "2px solid #cecece",
                                borderRadius: "10px",
                                padding: "0px 9px",
                              }}
                            />
                            <br />
                            <a href={`/add/badge/${tracker_id}`}>Add Badge</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="first_row">
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className="school"
                        style={{
                          backgroundColor: `${props.trackerInfo?.widgetColor}`,
                        }}
                      >
                        {/* <h4 className="text-center mt-3">
                        Elk Group High School
                      </h4> */}
                        <div dangerouslySetInnerHTML={Set()}></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="school mb-3 tracker-logo-wrapper">
                        <Image
                          className="rounded tracker-logo"
                          responsive="true"
                          fluid
                          title="Tracker logo"
                          src={props.trackerInfo?.siteLogo}
                        ></Image>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                {/************************accordion row********** */}
                <div className="second_row">
                  <div className="row">
                    <div className="col-lg-12">
                      <div
                        className="groups"
                        style={{
                          backgroundColor: `${props.trackerInfo?.widgetColor}`,
                        }}
                      >
                        <h3>Group </h3>
                        <Accordion defaultActiveKey="0">
                          {props.trackerInfo?.groups?.length > 0 ? (
                            props.trackerInfo?.groups?.map((t, i) => (
                              <Accordion.Item
                                key={t.id}
                                eventKey={i - 1}
                                style={{
                                  marginTop: "10px",
                                  margin: "20px 10px",
                                }}
                              >
                                <Accordion.Header
                                  onClick={() => {
                                    fetchUsersOfGroup(t.id);
                                    setSelectedGroup(t.id);
                                  }}
                                >
                                  {t.groupName}
                                  <i
                                    onClick={() => {
                                      setShowEditModal(true);
                                    }}
                                    className="fa fa-pencil-square-o ms-5 fs-4"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    onClick={() => deleteGroup(t.id)}
                                    className="fa fa-trash-o ms-5 fs-5"
                                    aria-hidden="true"
                                  ></i>
                                  {t.id === selectedGroup && (
                                    <Modal
                                      show={showEditModal}
                                      onHide={() => setShowEditModal(false)}
                                      size="lg"
                                      aria-labelledby="contained-modal-title-vcenter"
                                      centered
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                          Edit the group
                                        </Modal.Title>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <Form>
                                          <Form.Group
                                            as={Row}
                                            className="mb-3"
                                            controlId="formPlaintextEmail"
                                          >
                                            <Form.Label column="true" sm="2">
                                              Group Name
                                            </Form.Label>
                                            <Col sm="10">
                                              <Form.Control
                                                placeholder="Enter the new group name"
                                                type="text"
                                                value={groupName}
                                                onChange={(e) =>
                                                  setGroupName(e.target.value)
                                                }
                                              />
                                            </Col>
                                          </Form.Group>
                                        </Form>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          type="submit"
                                          variant="primary"
                                          onClick={() => {
                                            editGroup(t.id);
                                          }}
                                        >
                                          <i
                                            className="fa fa-pencil-square-o m-1"
                                            aria-hidden="true"
                                          ></i>
                                          Update Group
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  )}
                                </Accordion.Header>

                                <Accordion.Body
                                  style={{
                                    marginTop: "50px",
                                    position: "relative",
                                  }}
                                >
                                  <div className="text-end w-100 member">
                                    <Button
                                      className="btn pull-right"
                                      onClick={() => {
                                        setAddMemberResult(null);
                                        setGroupModalShow(true);
                                      }}
                                    >
                                      Add member
                                    </Button>
                                  </div>

                                  {t.id === selectedGroup && (
                                    <Modal
                                      show={groupModalShow}
                                      onHide={() => setGroupModalShow(false)}
                                      size="lg"
                                      aria-labelledby="contained-modal-title-vcenter"
                                      centered
                                    >
                                      <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                          {" "}
                                          Invite a Member{" "}
                                        </Modal.Title>
                                      </Modal.Header>

                                      <Modal.Body>
                                        <Form>
                                          <Form.Group
                                            as={Row}
                                            className="mb-3"
                                            controlId="formPlaintextEmail"
                                          >
                                            <Form.Label column="true" sm="2">
                                              {" "}
                                              User Email{" "}
                                            </Form.Label>
                                            <Col sm="10">
                                              <Form.Control
                                                placeholder="Enter an email address, or multiple emails separated by semicolons."
                                                type="text"
                                                value={userEmail}
                                                onChange={(e) =>
                                                  setUserEmail(e.target.value)
                                                }
                                              />
                                            </Col>
                                          </Form.Group>
                                        </Form>
                                        {addMemberResult?.map((member, i) => (
                                          <p key={i}>
                                            <span>{member.email}</span>
                                            <span
                                              className={
                                                !member.status
                                                  ? "badge bg-danger"
                                                  : member.status === "invited"
                                                  ? "badge bg-success"
                                                  : "badge bg-info"
                                              }
                                              style={{
                                                margin: "0px",
                                                marginLeft: "1rem",
                                                width: "auto",
                                              }}
                                            >
                                              {member.status
                                                ? member.status
                                                : "failed"}
                                            </span>
                                          </p>
                                        ))}
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          type="submit"
                                          variant="primary"
                                          onClick={() => {
                                            addMember(t.id);
                                          }}
                                        >
                                          Invite
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  )}

                                  <Table responsive striped bordered hover>
                                    <thead>
                                      <tr>
                                        <th
                                          colSpan="4"
                                          className="align-middle text-center"
                                        >
                                          <i
                                            onClick={() => {
                                              setCal(!cal);
                                            }}
                                            className="fa fa-calendar m-1 fs-5 pointer"
                                            aria-hidden="true"
                                          ></i>
                                          <Row className="align-middle text-center">
                                            {cal && t.id === selectedGroup && (
                                              <Calendar
                                                value={selectedDate}
                                                onChange={(date) => {
                                                  handleDateChanged(date);
                                                }}
                                                tileClassName={
                                                  updateCalendarTileClassName
                                                }
                                              />
                                            )}
                                          </Row>
                                          {t.badgeAllotmentFrequency}
                                          <br />
                                          <i
                                            className="fa fa-chevron-left m-1"
                                            style={{ cursor: "pointer" }}
                                            aria-hidden="true"
                                            onClick={() => {
                                              let prevDate = moment(
                                                selectedDate
                                              ).subtract(1, "days");
                                              handleDateChanged(prevDate);
                                            }}
                                          ></i>

                                          {moment(selectedDate).format("L")}

                                          <i
                                            className="fa fa-chevron-right m-1"
                                            aria-hidden="true"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              let nextDate = moment(
                                                selectedDate
                                              ).add(1, "days");
                                              handleDateChanged(nextDate);
                                            }}
                                          ></i>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th className="align-middle text-center">
                                          Group Member Name
                                        </th>

                                        {props.trackerInfo?.badges?.map(
                                          (b, index) => (
                                            <th
                                              className="align-middle text-center"
                                              key={index}
                                            >
                                              <Image
                                                style={{
                                                  height: "60px",
                                                  width: "60px",
                                                  resize: "contains",
                                                }}
                                                src={`${b.graphics}`}
                                                alt={b.altDescription}
                                                title={b.altDescription}
                                              />
                                              <p className="align-middle text-center">
                                                {b.badgeName}
                                              </p>
                                            </th>
                                          )
                                        )}

                                        <th className="align-middle text-center">
                                          Delete
                                        </th>
                                      </tr>
                                      {usersOfGroup.length > 0 ? (
                                        usersOfGroup.map((u, i) => (
                                          <tr key={i}>
                                            <td className="align-middle text-center">
                                              <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  handleUserClickInBadgeReward(
                                                    u.user_id,
                                                    t.id
                                                  )
                                                }
                                              >
                                                {u.user_info.name}
                                              </span>
                                              {u.user_id === selectedUser && (
                                                <Modal
                                                  show={userModalShow}
                                                  onHide={() =>
                                                    setUserModalShow(false)
                                                  }
                                                  size="lg"
                                                  aria-labelledby="contained-modal-title-vcenter"
                                                  centered
                                                >
                                                  <Modal.Header closeButton>
                                                    <Modal.Title id="contained-modal-title-vcenter">
                                                      Badges of User
                                                    </Modal.Title>
                                                  </Modal.Header>
                                                  <Modal.Body>
                                                    <Table responsive>
                                                      <thead>
                                                        <tr>
                                                          <th className="align-middle text-center">
                                                            Badges
                                                          </th>
                                                          {props.trackerInfo?.badges?.map(
                                                            (b, index) => (
                                                              <th
                                                                className="align-middle text-center"
                                                                key={index}
                                                              >
                                                                <Image
                                                                  style={{
                                                                    height:
                                                                      "60px",
                                                                    width:
                                                                      "60px",
                                                                  }}
                                                                  src={`${b.graphics}`}
                                                                  roundedCircle
                                                                />
                                                              </th>
                                                            )
                                                          )}
                                                          <th className="align-middle text-center">
                                                            Total Earned
                                                          </th>
                                                          <th className="align-middle text-center">
                                                            Total Redeemed
                                                          </th>
                                                          <th className="align-middle text-center">
                                                            Balance
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <th className="align-middle text-center">
                                                            {u.user_info.name}
                                                          </th>
                                                          {props.trackerInfo?.badges?.map(
                                                            (b, index) => (
                                                              <th
                                                                className="align-middle text-center"
                                                                key={index}
                                                              >
                                                                {countInArray(
                                                                  listOfAllocate,
                                                                  b.id
                                                                )}
                                                              </th>
                                                            )
                                                          )}
                                                          <th className="align-middle text-center">
                                                            {
                                                              accumulatedBadges?.total
                                                            }
                                                          </th>
                                                          <th className="align-middle text-center">
                                                            {
                                                              accumulatedBadges?.redeemed
                                                            }
                                                          </th>
                                                          <th className="align-middle text-center">
                                                            {
                                                              accumulatedBadges?.balance
                                                            }
                                                          </th>
                                                        </tr>
                                                      </tbody>
                                                    </Table>
                                                    <div
                                                      style={{
                                                        marginBottom: 20,
                                                      }}
                                                    >
                                                      <input
                                                        type="number"
                                                        id={`b_count${u.user_id}_${selectedGroup}_${i}`}
                                                        onChange={(e) => {
                                                          setNumberOfBadgesToRedeem(
                                                            e.target.value
                                                          );
                                                        }}
                                                        value={
                                                          numberOfBadgesToRedeem
                                                        }
                                                        min="0"
                                                        style={{ minWidth: 50 }}
                                                        max={
                                                          balanceBadgesOfSelectedUser
                                                        }
                                                        disabled={
                                                          balanceBadgesOfSelectedUser ===
                                                          0
                                                        }
                                                      />
                                                      <Button
                                                        variant="outline-primary"
                                                        className="px-3"
                                                        id="button-addon2"
                                                        style={{
                                                          marginLeft: 20,
                                                          height: 30,
                                                          paddingTop: 2,
                                                        }}
                                                        onClick={(ev) => {
                                                          handleRedeemBadge(
                                                            ev,
                                                            u.user_id
                                                          );
                                                        }}
                                                        disabled={
                                                          balanceBadgesOfSelectedUser ===
                                                          0
                                                        }
                                                      >
                                                        Redeem
                                                      </Button>
                                                    </div>

                                                    <InputGroup
                                                      className="mb-3"
                                                      size="md"
                                                    >
                                                      <FormControl
                                                        placeholder="Write any note here"
                                                        aria-label="note"
                                                        aria-describedby="basic-addon2"
                                                        value={note}
                                                        onChange={(e) =>
                                                          setNote(
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                      <Button
                                                        variant="outline-primary"
                                                        className="px-3"
                                                        id="button-addon2"
                                                        disabled={
                                                          !note || !note.length
                                                        }
                                                        onClick={() => {
                                                          if (
                                                            !note ||
                                                            !note.length
                                                          ) {
                                                            return;
                                                          }

                                                          props.groupActions.addNote(
                                                            {
                                                              note: note.trim(),
                                                              user_id:
                                                                u.user_id,
                                                            },
                                                            (data) => {
                                                              if (
                                                                data !== "error"
                                                              ) {
                                                                setNote("");
                                                                toast.success(
                                                                  "Note added"
                                                                );
                                                              }
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        Add Note
                                                      </Button>
                                                    </InputGroup>
                                                  </Modal.Body>
                                                </Modal>
                                              )}
                                            </td>
                                            {badgesOfGroupOnSelectedDate.length >
                                              0 &&
                                              props.trackerInfo?.badges?.map(
                                                (b, index) => (
                                                  <td
                                                    className="align-middle text-center"
                                                    key={index}
                                                  >
                                                    <Form.Check
                                                      type="checkbox"
                                                      checked={
                                                        badgesOfGroupOnSelectedDate.findIndex(
                                                          (bg) =>
                                                            bg.user_id ===
                                                              u.user_id &&
                                                            bg.badge_id === b.id
                                                        ) !== -1
                                                      }
                                                      onChange={(ev) => {
                                                        handleBadgeCheckbox(
                                                          ev,
                                                          u.user_id,
                                                          b.id,
                                                          t.id
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                )
                                              )}
                                            {badgesOfGroupOnSelectedDate.length ===
                                              0 &&
                                              props.trackerInfo?.badges?.map(
                                                (b, index) => (
                                                  <td
                                                    className="align-middle text-center"
                                                    key={index}
                                                  >
                                                    <Form.Check
                                                      type="checkbox"
                                                      onChange={(ev) => {
                                                        handleBadgeCheckbox(
                                                          ev,
                                                          u.user_id,
                                                          b.id,
                                                          t.id
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                )
                                              )}
                                            <td>
                                              <i
                                                className="fa fa-trash-o ms-5 fs-5 pointer text-danger"
                                                aria-hidden="true"
                                                onClick={() => {
                                                  deleteMember(
                                                    u.user_id,
                                                    u.group_id
                                                  );
                                                }}
                                              ></i>
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td className="text-center">
                                            <i
                                              className="fa fa-exclamation-circle"
                                              aria-hidden="true"
                                            ></i>
                                            There are no users in this group
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </Accordion.Body>
                              </Accordion.Item>
                            ))
                          ) : (
                            <div className="text-center">
                              <i
                                className="fa fa-exclamation-circle"
                                aria-hidden="true"
                              ></i>
                              There are no groups till now. Please create a
                              group.
                            </div>
                          )}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Tab>

          {/* MyBadges Tab */}
          <Tab eventKey="User" title="MyBadges">
            {props.trackerInfo?.groups.map((nam) => {
              if (
                nam.groupMembers.filter((gm) => gm.user_id === user?.id)
                  .length > 0
              ) {
                return (
                  <div
                    className="user"
                    style={{
                      backgroundColor: `${props.trackerInfo?.widgetColor}`,
                    }}
                    key={nam.id}
                  >
                    <Container>
                      <Row className="mb-5 text-center">
                        <Col md={4}>
                          <h2> {nam.groupName}</h2>
                        </Col>
                        <Col md={{ span: 4, offset: 4 }}></Col>
                      </Row>
                      <div
                        aria-controls="example-fade-text"
                        aria-expanded={open}
                        className="text-end mb-4"
                      >
                        <Button
                          variant="secondary"
                          className="px-4 py-2 m-3"
                          size="lg"
                          onClick={() =>
                            handleUserClickInGroupInfo(user.id, nam.id)
                          }
                        >
                          More Info
                        </Button>
                      </div>
                      <Collapse in={open === nam.id}>
                        <div id="example-fade-text">
                          <div
                            className="w-100 h-auto p-5 rounded"
                            style={{ border: "1px solid #a6a6a6" }}
                          >
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th className="align-middle text-center">
                                    Badges
                                  </th>
                                  {props.trackerInfo?.badges?.map(
                                    (b, index) => (
                                      <th
                                        className="align-middle text-center"
                                        key={index}
                                      >
                                        <Image
                                          style={{
                                            height: "60px",
                                            width: "60px",
                                          }}
                                          src={`${b.graphics}`}
                                          alt={b.altDescription}
                                          title={b.altDescription}
                                          roundedCircle
                                        />
                                        <p className="align-middle text-center">
                                          {b.badgeName}
                                        </p>
                                      </th>
                                    )
                                  )}
                                  <th className="align-middle text-center">
                                    Total Earned
                                  </th>
                                  <th className="align-middle text-center">
                                    Total Redeemed
                                  </th>
                                  <th className="align-middle text-center">
                                    Balance
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th className="align-middle text-center">
                                    {user.name}
                                  </th>
                                  {props.trackerInfo?.badges?.map(
                                    (b, index) => (
                                      <th
                                        className="align-middle text-center"
                                        key={index}
                                      >
                                        {countInArray(listOfAllocate, b.id)}
                                      </th>
                                    )
                                  )}
                                  <th className="align-middle text-center">
                                    {accumulatedBadges?.total}
                                  </th>
                                  <th className="align-middle text-center">
                                    {accumulatedBadges?.redeemed}
                                  </th>
                                  <th className="align-middle text-center">
                                    {accumulatedBadges?.balance}
                                  </th>
                                </tr>
                              </tbody>
                            </Table>
                            <div className="text-start fs-3 font-bold">
                              <i
                                className="fa fa-pencil-square-o mr-2"
                                aria-hidden="true"
                              ></i>
                              Notes
                            </div>
                            <hr style={{ height: "3px" }} />
                            {notes.map((note, index) => (
                              <div className="text-start mb-4" key={index}>
                                <span>
                                  {moment(note.createdAt).format("DD/MM/YYYY")}
                                </span>
                                <div className="p-4 border border-3 rounded">
                                  {note.note}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Collapse>
                    </Container>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    allTrackerGroup: state.tracker.allTrackerGroup,
    trackerInfo: state.tracker.trackerInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    groupActions: bindActionCreators(groupActions, dispatch),
    trackerActions: bindActionCreators(trackerAction, dispatch),
    badgeActions: bindActionCreators(badgeActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TrackerHome);
