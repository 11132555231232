import * as types from "../action-types";

export const badgeReducer = (state = [], action) => {
  switch (action.type) {
    case types.GET_ALL_BADGES_SUCCESS:
      return {
        ...state,
        allBadges: action.payload,
      };
    case types.GET_ALL_BADGES_REJECTED:
      return {
        ...state,
        allBadges: action.payload,
      };

    default:
      return state;
  }
};
