const REQUIRED_SETTINGS = [
  'REACT_APP_BASE_URL',
  'REACT_APP_API_URL',
]

function validateRequiredSetting(){
  const settings = Object.keys(process.env)
  .filter(k=>k.indexOf('REACT_APP_') !== -1)

  if(!settings || !settings.length){
    throw new Error('Required setting is missing')
  }

  REQUIRED_SETTINGS.forEach((k)=>{
    if(!settings.includes(k)){
      throw new Error('Required setting is missing')
    }
  })
}

validateRequiredSetting()

export const ui_base_url = process.env.REACT_APP_BASE_URL
export const baseUrl = process.env.REACT_APP_API_URL
