import React, { useState } from "react";
import validate from "./validateInfo";
import useForm from "./useForm";
import axios from "axios";
import { baseUrl } from "../constants";
import { useHistory } from "react-router-dom";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
const SignUp = ({ submitForm }) => {
  const history = useHistory();
  const { errors } = useForm(submitForm, validate);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [isTracker, setIsTracker] = useState(false);
  // const [isStudent, setIsStudent] = useState(false);
  const submit = (e) => {
    e.preventDefault();
    let user_data = {
      name: name,
      password: password,
      email: email,
      isTracker: true,
      isStudent: false,
    };
    try {
      axios({
        url: `${baseUrl}/register`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: user_data,
      })
        .then((response) => {
          console.log(response);
          toast.success("Tracker registered successfully");
          history.push("/");
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="center">
      <Col md={6}>
        <Card>
          <Card.Body>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginBottom: "20px",
              }}
            >
              <h4> Get started with us today!</h4>
              <h4>Create your account by filling out the information below.</h4>
            </div>

            <Form>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder="Enter your name"
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder="Enter your email"
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Password
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder="Enter your password"
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              {/* <Form>
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3 text-center">
                    <Form.Check
                      inline
                      label="Tracker"
                      name="radio"
                      type={type}
                      id={`inline-${type}-1`}
                      onClick={() => setIsTracker(true)}
                    />
                    <Form.Check
                      inline
                      label="User"
                      name="radio"
                      type={type}
                      onClick={() => setIsStudent(true)}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </Form> */}
              {errors.password && <p>{errors.password}</p>}
              <Button
                type="submit"
                className="pull-right"
                size="lg"
                variant="primary"
                onClick={submit}
              >
                Register
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default SignUp;
