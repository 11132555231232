import React from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../assest/logo.png";

function Navbar(props) {
  const history = useHistory();
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("isremember");
    history.push("/");
    window.location.reload();
    console.log(props);
  };

  return (
    <>
      <div className="navbar" style={{ backgroundColor: `${props.color}` }}>
        <Link to="/" className="menu-bars">
        <img src={logo} style={{ width: '8%'}} title="My Badge Tracker" alt="logo" />
        </Link>
        <button onClick={logOut} className="menu-bar">
          Logout<i className="fa fa-power-off" aria-hidden="true"></i>
        </button>
      </div>
      <div className="welcome">
        <h5>Welcome {userDetails?.name}</h5>
      </div>
    </>
  );
}

export default Navbar;
