// export const ADD_TRACKERS = "ADD_TRACKERS";
export const GET_ALL_TRACKERS_SUCCESS = "GET_ALL_TRACKERS_SUCCESS";
export const GET_ALL_TRACKERS_REJECTED = "GET_ALL_TRACKERS_REJECTED";

export const GET_ALL_BADGES_SUCCESS = "GET_ALL_BADGES_SUCCESS";
export const GET_ALL_BADGES_REJECTED = "GET_ALL_BADGES_REJECTED";

export const GET_ALL_TRACKERS_GROUP_SUCCESS = "GET_ALL_TRACKERS_GROUP_SUCCESS";
export const GET_ALL_TRACKERS_GROUP_REJECTED =
  "GET_ALL_TRACKERS_GROUP_REJECTED";

export const GET_TRACKERS_SUCCESS = "GET_TRACKERS_SUCCESS";
export const GET_TRACKERS_REJECTED = "GET_TRACKERS_REJECTED";

export const GET_ALL_TRACKERS_INVITED_SUCCESS =
  "GET_ALL_TRACKERS_INVITED_SUCCESS";
export const GET_ALL_TRACKERS_INVITED_REJECTED =
  "GET_ALL_TRACKERS_INVITED_REJECTED";
