import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as trackerActions from "../redux/actions/trackerActions";
import * as groupActions from "../redux/actions/groupAction";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import Navbar from "./Navbar";
import { toast } from "react-toastify";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Form,
  Container,
  Row,
  Col,
  Button,
  Table,
  Modal,
  Image,
  Tabs,
  Tab,
  Collapse,
  Alert,
} from "react-bootstrap";
import "../tracker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Calendar from "react-calendar";

function Dashboard(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [cal, setCal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toLocaleDateString()
  );
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [allocate, setAllocate] = useState([]);
  const [totalList, setTotalList] = useState([]);
  const { allTrackers, trackerInvited } = props;

  const history = useHistory();
  const [groupName, setGroupName] = useState("");
  const [siteName, setSiteName] = useState("");
  const [logo, setLogo] = useState("");
  const [background, setBackground] = useState("#ffd2cf");
  const [banner, setBanner] = useState("#57c1fa");
  const [widget, setWidget] = useState("#fffb2b");
  const [modalShow, setModalShow] = React.useState(false);
  const [description, setDescription] = useState("");
  const [showConfirmToDeleteTrackerModal, setShowConfirmToDeleteTrackerModal] =
    useState(false);
  const [focusTracker, setFocusTracker] = useState({});
  const [confirmTrackerName, setConfirmTrackerName] = useState("");
  const [confirmedTrackerNameMatch, setConfirmedTrackerNameMatch] =
    useState(true);

  useEffect(() => {
    props.trackerActions.getAllTrackers(user?.id);
    // props.groupActions.getMembersWithTracker(user?.id);
  }, []);

  useEffect(() => {
    if (!modalShow) {
      setSiteName("");
      setDescription("");
      setLogo("");
      setBanner("#57c1fa");
      setBackground("#ffd2cf");
      setWidget("#fffb2b");
    }
  }, [modalShow]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      siteName: siteName,
      description: description,
      siteLogo: logo,
      bannerColor: banner,
      backgroundColor: background,
      widgetColor: widget,
      user_id: user.id,
    };

    props.trackerActions.addTracker(data, (data) => {
      if (data !== "error") {
        toast.success("tracker added successfully");
        props.trackerActions.getAllTrackers(user.id);
        setModalShow(false);
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setLogo(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const goToTrackerHome = (id) => {
    history.push(`/tracker/${id}`);
  };
  const handleChange = (e, editor) => {
    setDescription(editor.getData());
  };
  const findBadgeByUserDate = (user_id, date) => {
    props.badgeActions.findAllocateByUserDate(user_id, date, (data) => {
      setAllocate(data.map((item) => item.badge_id));
    });
  };

  const countInArray = (array, what) => {
    return array.filter((item) => item === what).length;
  };

  const fetchMyGroups = (type) => {
    if (type === "User") {
      props.groupActions.fetchMyApprovedGroup(user?.id, (data) => {
        // setAllocate(data.map((item) => item.badge_id));
      });
    }
  };

  const handleShowConfirmToDeleteTrackerModal = (tracker) => {
    setFocusTracker(tracker);
    setConfirmTrackerName("");
    setShowConfirmToDeleteTrackerModal(true);
  };

  const handleConfirmToDeleteTracker = (event) => {
    if (confirmTrackerName !== focusTracker.siteName) {
      setConfirmedTrackerNameMatch(false);

      return;
    }
    setConfirmedTrackerNameMatch(true);

    // perform delete the target tracker
    props.trackerActions.trashTracker(focusTracker.id, (data) => {
      if (data === "error") {
        toast.error("Something went wrong");

        return;
      }

      toast.success(`Tracker "${focusTracker.siteName}" has been deleted`);
      props.trackerActions.getAllTrackers(user.id);
      setShowConfirmToDeleteTrackerModal(false);
    });
    //

    // finally
    event.preventDefault();
  };

  return (
    <>
      <Navbar color="#66d0e8" />
      {/* <Tabs
        defaultActiveKey="Tracker"
        id="uncontrolled-tab-example"
        className="m-3 fs-5"
        onSelect={(k) => fetchMyGroups(k)}
      >
        <Tab eventKey="Tracker" title="MyTracker"> */}
      <div className="container" id="form1">
        <div className="m-50">
          <Row className="justify-content-end">
            <Button
              className="mb-2 col-3"
              variant="primary"
              size="lg"
              onClick={() => setModalShow(true)}
            >
              <i className="fa fa-plus m-1" aria-hidden="true"></i>Add Tracker
            </Button>
          </Row>
          <Row className="m-25">
            {!allTrackers && (
              <div className="text-center">
                {/* <Spinner animation="border" variant="primary" /> */}
              </div>
            )}
            {allTrackers && allTrackers.length > 0 ? (
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Tracker Logo</th>
                    <th>Tracker Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {allTrackers?.map((t) => (
                    <tr key={t.id}>
                      <td className="align-middle">
                        <Image
                          style={{ height: "100px", width: "100px" }}
                          src={`${t.siteLogo}`}
                          alt={t.altDescription}
                          title={t.altDescription}
                          roundedCircle
                        />
                      </td>
                      <td className="align-middle">
                        <Button
                          variant="link"
                          href="#"
                          onClick={() => goToTrackerHome(t.id)}
                        >
                          {t.siteName}
                        </Button>
                      </td>
                      <td className="align-middle">
                        <>
                          {t.user_id === user.id && (
                            <Button
                              variant="danger"
                              onClick={() =>
                                handleShowConfirmToDeleteTrackerModal(t)
                              }
                            >
                              Delete
                            </Button>
                          )}
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="text-center fs-5">
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                There are no active trackers. Please add a tracker.
              </div>
            )}
          </Row>
        </div>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Create Tracker Site
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group as={Row} className="mb-3" controlId="formSiteName">
                <Form.Label column sm="2">
                  Site Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    placeholder="Enter the site name"
                    type="text"
                    value={siteName}
                    onChange={(e) => setSiteName(e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="formDescription">
                <Form.Label column sm="2">
                  Description
                </Form.Label>
                <Col sm="10">
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={(e, editor) => {
                      handleChange(e, editor);
                    }}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="formSiteLogo">
                <Form.Label column sm="2">
                  Site Logo
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      uploadImage(e);
                    }}
                  />
                </Col>
              </Form.Group>

              <h4>Color Scheme:</h4>
              <Row>
                <Col sm={6}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formBannerColor"
                  >
                    <Form.Label column sm="4">
                      {" "}
                      Banner{" "}
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="color"
                        value={banner}
                        onChange={(e) => setBanner(e.target.value)}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formBackgroundColor"
                  >
                    <Form.Label column sm="4">
                      Background
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="color"
                        value={background}
                        onChange={(e) => setBackground(e.target.value)}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formWidgetColor"
                  >
                    <Form.Label column sm="4">
                      Widget
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="color"
                        value={widget}
                        onChange={(e) => setWidget(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <div
                    className="w-100 h-100"
                    style={{
                      border: "2px solid black",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      className="mb-0"
                      style={{
                        backgroundColor: `${banner}`,
                        minHeight: "25%",
                      }}
                    >
                      <p className="text-center">Banner</p>
                    </div>
                    <div
                      // className="h-75"
                      style={{
                        backgroundColor: `${background}`,
                        minHeight: "75%",
                      }}
                    >
                      <span className="h-25">Background</span>
                      <div
                        className="w-50 h-75 mt-2"
                        style={{ backgroundColor: `${widget}` }}
                      >
                        <p className="text-center my-auto">Widget</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              // className="d-block m-auto mb-6 py-2 px-4"
              // size="lg"
              variant="primary"
              onClick={handleSubmit}
            >
              Add Tracker
            </Button>

            <Button onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        {/* Confirm to delete tracker modal */}
        <Modal
          show={showConfirmToDeleteTrackerModal}
          onHide={() => setShowConfirmToDeleteTrackerModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete tracker</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <p>Are you sure you want to delete this tracker?</p>
              <p>
                Please type a tracker name "{focusTracker.siteName}" to confirm
              </p>
              <Form.Group className="mb-3" controlId="formConfirmTrackerName">
                <Form.Control
                  type="text"
                  value={confirmTrackerName}
                  onChange={(e) => setConfirmTrackerName(e.target.value)}
                />
                {!confirmedTrackerNameMatch ? (
                  <Alert variant="danger">Tracker name does not match</Alert>
                ) : (
                  ""
                )}
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmToDeleteTrackerModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmToDeleteTracker}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* </Tab> */}
      {/* <Tab eventKey="User" title="User">
          <div className="container" id="form1">
            <div className="m-50">
              <Row className="m-25">
                <h2 className="mb-5">All Trackers</h2>
                {!trackerInvited && (
                  <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
                {trackerInvited && trackerInvited.length > 0 ? (
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Tracker Logo</th>
                        <th>Tracker Name</th>
                        <th>Banner Color</th>
                        <th>Background Color</th>
                        <th>Widget Color</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trackerInvited?.map((t) => (
                        <tr onClick={() => goToTrackerHome(t.tracker_info.id)}>
                          <td>
                            <Image
                              style={{ height: "100px", width: "100px" }}
                              src={`${t.tracker_info.siteLogo}`}
                              roundedCircle
                            />
                          </td>
                          <td>{t.tracker_info.siteName}</td>
                          <td>
                            <Avatar
                              color={t.tracker_info.backgroundColor}
                              name={t.tracker_info.backgroundColor}
                            />
                          </td>
                          <td>
                            <Avatar
                              color={t.tracker_info.bannerColor}
                              name={t.tracker_info.bannerColor}
                            />
                          </td>
                          <td>
                            <Avatar
                              color={t.tracker_info.widgetColor}
                              name={t.tracker_info.widgetColor}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="text-center fs-5">
                    <i
                      className="fa fa-exclamation-circle"
                      aria-hidden="true"
                    ></i>
                    There are no trackers who have invited you
                  </div>
                )}
              </Row>
            </div>
          </div>
        </Tab> */}
      {/* <Tab eventKey="User" title="MyBadges">


        <div
              className="user"
              style={{
                backgroundColor: `${props.trackerInfo?.widgetColor}`,
              }}
            >
              <Container>
                <Row className="mb-5 text-center">
                <Col md={4}>
                    {props.trackerInfo?.groups.map((nam) => (
                      <h2> {nam.groupName}</h2>
                    ))}
                  </Col>

                  <Col md={{ span: 4, offset: 4 }}>
                    <i
                      className="fa fa-calendar fs-3 pointer"
                      aria-hidden="true"
                      onClick={() => {
                        setCal(!cal);
                      }}
                    ></i>
                    {cal && (
                      <Calendar
                        onChange={(date) => {
                          setAllocate([]);
                          console.log(date.toLocaleDateString());
                          findBadgeByUserDate(
                            user.id,
                            date.toLocaleDateString()
                          );
                          setSelectedDate(date.toLocaleDateString());
                        }}
                      />
                    )}
                    <p className="fs-5">
                      <i
                        className="fa fa-chevron-left m-1"
                        style={{ cursor: "pointer" }}
                        aria-hidden="true"
                        onClick={() => {
                          setAllocate([]);
                          let prevDate = moment(selectedDate, "DD/MM/YYYY")
                            .subtract(1, "days")
                            .format("DD/MM/YYYY");
                          console.log(prevDate);
                          findBadgeByUserDate(user.id, prevDate);
                          setSelectedDate(prevDate);
                        }}
                      ></i>
                      {selectedDate}
                      <i
                        className="fa fa-chevron-right m-1"
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setAllocate([]);
                          let nextDate = moment(selectedDate, "DD/MM/YYYY")
                            .add(1, "days")
                            .format("DD/MM/YYYY");
                          console.log(nextDate);
                          findBadgeByUserDate(user.id, nextDate);
                          setSelectedDate(nextDate);
                        }}
                      ></i>
                    </p>
                  </Col>
                </Row>

                <Table responsive>
                  <thead>
                    <tr>
                      <th className="align-middle text-center">Badges</th>
                      {props.trackerInfo?.badges?.map((b, index) => (
                        <th className="align-middle text-center" key={index}>
                          <Image
                            style={{
                              height: "60px",
                              width: "60px",
                            }}
                            src={`${b.graphics}`}
                            alt={b.altDescription}
                            title={b.altDescription}
                            roundedCircle
                          />
                        </th>
                      ))}
                      <th className="align-middle text-center">Total Earned</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="align-middle text-center">{user?.name}</th>
                      {props.trackerInfo?.badges?.map((b, index) => (
                        <th className="align-middle text-center" key={index}>
                          {countInArray(allocate, b.id)}
                        </th>
                      ))}
                      <th className="align-middle text-center">
                        {props.trackerInfo?.badges
                          ?.map((b) => countInArray(allocate, b.id))
                          .reduce((a, b) => a + b, 0)}
                      </th>
                    </tr>
                  </tbody>
                </Table>
                <div
                  aria-controls="example-fade-text"
                  aria-expanded={open}
                  className="text-end mb-4"
                >
                  <Button
                    variant="secondary"
                    className="px-4 py-2 m-3"
                    size="lg"
                    onClick={() => {
                      setOpen(!open);
                      props.badgeActions.findUserBadges(user.id, (data) => {
                        if (data !== "error")
                          setTotalList(data.map((item) => item.badge_id));
                      });
                      props.badgeActions.findNotes(user.id, (data) => {
                        if (data !== "error") setNotes(data);
                      });
                    }}
                  >
                    More Info
                  </Button>
                </div>
                <Collapse in={open}>
                  <div id="example-fade-text">
                    <div
                      className="w-100 h-auto p-5 rounded"
                      style={{ border: "1px solid #a6a6a6" }}
                    >
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="align-middle text-center">Badges</th>
                            {props.trackerInfo?.badges?.map((b, index) => (
                              <th className="align-middle text-center" key={index}>

                                <Image style={{height: "60px", width: "60px", resize: "contains" }}
                                       src={`${b.graphics}`}
                                       alt={b.altDescription}
                                       title={b.altDescription}
                                       roundedCircle
                                />
                              </th>
                            ))}
                            <th className="align-middle text-center">
                              Total Earned
                            </th>
                            <th className="align-middle text-center">
                              Total Redeemed
                            </th>
                            <th className="align-middle text-center">
                              Balance
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="align-middle text-center">
                              {user.name}
                            </th>
                            {props.trackerInfo?.badges?.map((b, index) => (
                              <th
                                className="align-middle text-center"
                                key={index}
                              >
                                {countInArray(totalList, b.id)}
                              </th>
                            ))}
                            <th className="align-middle text-center">
                              {props.trackerInfo?.badges
                                ?.map((b) => countInArray(totalList, b.id))
                                .reduce((a, b) => a + b, 0)}
                            </th>
                            <th className="align-middle text-center">0</th>
                            <th className="align-middle text-center">0</th>
                          </tr>
                        </tbody>
                      </Table>
                      <div className="text-start fs-3 font-bold">
                        <i className="fa fa-pencil-square-o mr-2"  aria-hidden="true"
                        ></i>
                        Notes
                      </div>
                      <hr style={{ height: "3px" }} />
                      {notes.map((note, index) => (
                        <div className="text-start mb-4" key={index}>
                          <span>
                            {moment(note.createdAt).format("DD/MM/YYYY")}
                          </span>
                          <div className="p-4 border border-3 rounded">
                            {note.note}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Collapse>
              </Container>
            </div>
          </Tab>
      </Tabs> */}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    allTrackers: state.tracker.allTrackers,
    trackerInvited: state.tracker.trackerInvited,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    trackerActions: bindActionCreators(trackerActions, dispatch),
    groupActions: bindActionCreators(groupActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
