import * as types from "../action-types";
import axios from "axios";
import { baseUrl } from "../../constants";
import { getHeaders } from "../../config.js";

export const getAllBadgesByTracker = (id, callback) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/badge/getbytracker/${id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        if (response) {
          if (response.data) {
            callback(response.data.data);
            // dispatch({
            //   type: types.GET_ALL_BADGES_SUCCESS,
            //   payload: response.data.data,
            // });
          }
        }
      })
      .catch((error) => {
        callback(error);
        // dispatch({
        //   type: types.GET_ALL_BADGES_REJECTED,
        //   payload: error,
        // });
      });
  };
};
export const addBadge = (jsonObj, callback) => {
  return async (dispatch) => {
    axios({
      method: "POST",
      url: `${baseUrl}/badge/add`,
      headers: await getHeaders(),
      data: jsonObj,
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const editBadge = (id, jsonObj, callback) => {
  return async (dispatch) => {
    axios({
      method: "PUT",
      url: `${baseUrl}/badge/edit/${id}`,
      headers: await getHeaders(),
      data: jsonObj,
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};
export const deleteBadge = (id, callback) => {
  return async (dispatch) => {
    axios({
      method: "DELETE",
      url: `${baseUrl}/badge/delete/${id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const getRedeemedBadges = (jsonObj, callback) => {
  return async (dispatch) => {
    axios({
      method: "POST",
      url: `${baseUrl}/redeemedBadge/get`,
      headers: await getHeaders(),
      data: jsonObj,
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const redeemBadge = (jsonObj, callback) => {
  return async (dispatch) => {
    axios({
      method: "POST",
      url: `${baseUrl}/redeemBadge`,
      headers: await getHeaders(),
      data: jsonObj,
    })
      .then((response) => {
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const allocateBadge = (jsonObj, callback) => {
  return async (dispatch) => {
    axios({
      method: "POST",
      url: `${baseUrl}/allocatedBadge/add`,
      headers: await getHeaders(),
      data: jsonObj,
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const findAllocateBadge = (user_id, group_id, callback) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/allocatedBadge/getofuser/${user_id}/${group_id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const findUserBadges = (user_id, callback) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/allocatedBadge/getbyuser/${user_id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const findBadgesByUserAndGroup = (user_id, groupId, callback) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/allocatedBadge?user=${user_id}&group=${groupId}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const findAllocateByUserDate = (user_id, date, callback) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/allocatedBadge/getofuserdate/${user_id}?date=${date}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const findAllocatebyGroup = (group_id, date, callback) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/allocatedBadge/getbygroup/${group_id}?date=${date}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const findNotes = (user_id, callback) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/note/getbyuser/${user_id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        // console.log("response ", response.data)
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};
