import * as types from "../action-types";
import axios from "axios";
import { baseUrl } from "../../constants";
import { getHeaders } from "../../config.js";

export const getAllTrackers = (id) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/tracker/getbyuser/${id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        if (response) {
          if (response.data) {
            dispatch({
              type: types.GET_ALL_TRACKERS_SUCCESS,
              payload: response.data,
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ALL_TRACKERS_REJECTED,
          payload: error,
        });
      });
  };
};
export const addTracker = (jsonObj, callback) => {
  return async (dispatch) => {
    axios({
      method: "post",
      url: `${baseUrl}/tracker/add`,
      headers: await getHeaders(),
      data: jsonObj,
    })
      .then((response) => {
        console.log("response ", response.data.data);
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};
export const editTracker = (id, jsonObj, callback) => {
  return async (dispatch) => {
    axios({
      method: "PUT",
      url: `${baseUrl}/tracker/edit/${id}`,
      headers: await getHeaders(),
      data: jsonObj,
    })
      .then((response) => {
        console.log("response ", response.data);
        callback(response.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};
export const getTrackers = (id) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/tracker/get/${id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        if (response) {
          if (response.data) {
            dispatch({
              type: types.GET_TRACKERS_SUCCESS,
              payload: response.data,
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: types.GET_TRACKERS_REJECTED,
          payload: error,
        });
      });
  };
};
// export const getTrackerByUser = (id, callback) => {
//   return async (dispatch) => {
//     axios({
//       method: "GET",
//       url: `${baseUrl}/tracker/getbyuser/${id}`,
//       headers: await getHeaders(),
//     })
//       .then((response) => {
//         if (response) {
//           if (response.data) {
//             callback(response.data.data);
//           }
//         }
//       })
//       .catch((error) => {
//         if (error.response) {
//           callback("error");
//         }
//       });
//   };
// };

export const trashTracker = (id, callback) => {
  return async (dispatch) => {
    axios({
      method: "PUT",
      url: `${baseUrl}/tracker/${id}/trash`,
      headers: await getHeaders(),
    })
      .then((response) => {
        console.log("response ", response.data);
        callback(response.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const deleteTracker = (id, callback) => {
  return async (dispatch) => {
    axios({
      method: "DELETE",
      url: `${baseUrl}/tracker/${id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};
