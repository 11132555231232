import { combineReducers } from "redux";

import { trackerReducer } from "./trackerReducer";
import { badgeReducer } from "./badgeReducer";

const rootReducer = combineReducers({
  tracker: trackerReducer,
  badge: badgeReducer,
});

export default rootReducer;
