import * as types from "../action-types";

export const trackerReducer = (state = [], action) => {
  switch (action.type) {
    // case types.GET_ALL_TRACKERS_REJECTED:
    //   return {
    //     ...state,
    //     allTrackers: action.payload,
    //   };

    case types.GET_ALL_TRACKERS_SUCCESS:
      return {
        ...state,
        allTrackers: action.payload.data,
      };

    case types.GET_ALL_TRACKERS_GROUP_SUCCESS:
      console.log(action.payload.data);
      return {
        ...state,
        allTrackerGroup: action.payload.data,
      };

    case types.GET_TRACKERS_SUCCESS:
      return {
        ...state,
        trackerInfo: action.payload.data,
      };

    case types.GET_ALL_TRACKERS_INVITED_SUCCESS:
      return {
        ...state,
        trackerInvited: action.payload.data,
      };

    default:
      return state;
  }
};
