import React, { useState, useEffect, useRef } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import {
  InputGroup,
  FormControl,
  Button,
  Modal,
  Image,
  Form,
  Row,
  Col,
} from "react-bootstrap";

function BadgeForm(props) {
  const [badgeName, setBadgeName] = useState("");
  const [badgeDescription, setBadgeDescription] = useState("");
  const [altDescription, setAltDescription] = useState("");
  const [graphics, setGraphics] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  // const inputRef = useRef(null);

  // useEffect(() => {
  //   inputRef.current?.focus();
  // });
  const resetForm = () => {
    setBadgeName("");
    setBadgeDescription("");
    setAltDescription("");
    setGraphics("");
  };

  useEffect(() => {
    props.show !== null && setModalShow(props.show);
  }, [props]);

  useEffect(() => {
    !modalShow && resetForm();
  }, [modalShow]);

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setGraphics(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({
      badgeName,
      badgeDescription,
      graphics,
      altDescription,
    });
  };
  return (
    <>
      <div style={{ width: "100%" }} className="d-grid gap-2">
        {/* <InputGroup className="mb-3" style={{ height: "60px" }}>
        <FormControl
          placeholder="Add a Badge"
          aria-label="Add a Badge"
          aria-describedby="basic-addon2"
          ref={inputRef}
          value={input}
          onChange={handleChange}
        />
      </InputGroup> */}

        <Button
          variant="outline-secondary"
          onClick={() => setModalShow(true)}
          className="badge-button"
          id="button-addon2"
        >
          <AiOutlinePlus style={{ fontSize: "25px", color: "#333333" }} /> Add
          Badge
        </Button>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Badge
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                Badge Name
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  placeholder="Enter the badge name"
                  type="text"
                  value={badgeName}
                  onChange={(e) => setBadgeName(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                Badge Description
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  placeholder="Enter the badge description"
                  type="text"
                  value={badgeDescription}
                  onChange={(e) => setBadgeDescription(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="2">
                Badge Graphics
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="file"
                  onChange={(e) => {
                    uploadImage(e);
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                Alt Description
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  placeholder="Enter the fallback text"
                  type="text"
                  value={altDescription}
                  onChange={(e) => setAltDescription(e.target.value)}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            // className="d-block m-auto mb-6 py-2 px-4"
            // size="lg"
            variant="primary"
            onClick={handleSubmit}
          >
            Create Badge
          </Button>

          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BadgeForm;
