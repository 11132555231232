import React, { useState } from "react";
import axios from "axios";
import queryString from "query-string";
import {
  Form,
  Row,
  Col,
  Button,
  Card,
  Nav,
  Tab,
  Tabs,
  Container,
} from "react-bootstrap";
import validate from "./validateInfo";
import useForm from "./useForm";
import { baseUrl } from "../constants";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const Verification = (props) => {
  const history = useHistory();
  const { errors } = useForm(props.submitForm, validate);
  const [logingEemail, setLoginEmail] = useState("");
  const [logingpassword, setLoginPassword] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [activeTab, setActiveTab] = useState("signup");

  console.log(props.location.search);
  const parsed = queryString.parse(props.location.search);
  const { code } = parsed;
  if (!code) {
    activeTab !== "login" && setActiveTab("login");
  }

  const submit = (e) => {
    e.preventDefault();

    let user_data = {
      name: name,
      password: password,
      email: email,
      isTracker: false,
      isStudent: true,
    };

    try {
      // axios({
      //   url: `${baseUrl}/member/getbyemail?email=${email}`,
      //   method: "GET",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      // })
      //   .then((res) => {
      //     console.log(res.data.data);
      //     if (res.data.data === null) {
      //       toast.error("This email ID is not invited to any group");
      //     } else {
      axios({
        url: `${baseUrl}/register`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: user_data,
      })
        .then((response) => {
          console.log("==>resgistration response", response.data.data);
          let user_id = response.data.data.id;
          toast.success("User registered successfully");
          axios({
            url: `${baseUrl}/group/getbytoken/${code}`,
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              console.log(res);
              let group_id = res.data.data.id;
              axios({
                url: `${baseUrl}/member/edit/${group_id}`,
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                data: { user_id, email },
              })
                .then((res) => {
                  setActiveTab("login");
                  toast.success("You have been added to the group");
                })
                .catch((err) => {
                  toast.error("Something went wrong");
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
      //   }
      // })
      // .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };

  const loginsubmit = (e) => {
    e.preventDefault();
    let user_login_data = {
      email: logingEemail,
      password: logingpassword,
    };

    try {
      // axios({
      //   url: `${baseUrl}/member/getbyemail?email=${email}`,
      //   method: "GET",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      // })
      //   .then((res) => {
      //     console.log(res.data.data);
      //     if (res.data.data === null) {
      //       toast.error("This email ID is not invited to any group");
      //     } else {

      axios({
        method: "post",
        url: `${baseUrl}/login`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: user_login_data,
      })
        .then((response) => {
          console.log("==>login response", response.data.data);
          let user_id = response.data.data.id;
          toast.success("User login successfully");
          axios({
            url: `${baseUrl}/group/getbytoken/${code}`,
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              console.log(res);
              let group_id = res.data.data.id;
              axios({
                url: `${baseUrl}/member/edit/${group_id}`,
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                data: { user_id, email: logingEemail },
              })
                .then((res) => {
                  console.log(res);
                  toast.success("You have been added to the group");
                  localStorage.setItem("token", response.data.data.token);
                  localStorage.setItem(
                    "user",
                    JSON.stringify(response.data.data)
                  );
                  const user = JSON.parse(localStorage.getItem("user"));
                  console.log(user.isTracker);
                  // { user.isTracker ? history.push("/dashboard") : history.push("/user")};
                  // if (user.isTracker) {
                  history.push("/dashboard");
                  // }
                  // else {
                  //   history.push("/user")
                  // }
                  window.location.reload();
                })
                .catch((err) => {
                  toast.error("Something went wrong");
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
      //   }
      // })
      // .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="center">
        <Col md={8}>
          <Container>
            <Card>
              <Card.Body>
                <Tabs
                  activeKey={activeTab}
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3"
                  onSelect={(key) => {
                    setActiveTab(key);
                  }}
                >
                  <Tab eventKey="login" title="Login">
                    <Form>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label column sm="2">
                          {" "}
                          Email{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            placeholder="Email Address"
                            className="mt-2"
                            type="email"
                            value={logingEemail}
                            onChange={(e) => setLoginEmail(e.target.value)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label column sm="2">
                          {" "}
                          Password{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            placeholder="Password"
                            type="password"
                            className=" mt-2"
                            value={logingpassword}
                            onChange={(e) => setLoginPassword(e.target.value)}
                          />
                        </Col>
                      </Form.Group>

                      <Button
                        type="submit"
                        className="pull-right mt-2"
                        ze="md"
                        variant="primary"
                        onClick={loginsubmit}
                      >
                        SignIn
                      </Button>
                    </Form>
                  </Tab>

                  <Tab
                    eventKey="signup"
                    title="Sign up"
                    onClick={() => {
                      console.log("signup clicked");
                      setActiveTab("signup");
                    }}
                  >
                    <Form>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label column sm="2">
                          {" "}
                          Name{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            placeholder="Enter your name"
                            type="text"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label column sm="2">
                          Email
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            placeholder="Enter your email"
                            type="text"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label column sm="2">
                          Password
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            placeholder="Enter your password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                        </Col>
                      </Form.Group>
                      {errors.password && <p>{errors.password}</p>}
                      <Button
                        type="submit"
                        className="pull-right"
                        ze="lg"
                        variant="primary"
                        onClick={submit}
                      >
                        Register
                      </Button>
                    </Form>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </div>
    </>
  );
};

export default Verification;
