import * as types from "../action-types";
import axios from "axios";
import { baseUrl } from "../../constants";
import { getHeaders } from "../../config.js";

export const addGroupForTracker = (jsonObj, callback) => {
  return async (dispatch) => {
    axios({
      method: "post",
      url: `${baseUrl}/group/add`,
      headers: await getHeaders(),
      data: jsonObj,
    })
      .then((response) => {
        console.log("response ", response.data.data);
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const getGroupByTrackerId = (tid) => {
  return async (dispatch) => {
    axios({
      method: "get",
      url: `${baseUrl}/group/getbytracker/${tid}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        console.log(response.data);
        dispatch({
          type: types.GET_ALL_TRACKERS_GROUP_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ALL_TRACKERS_SUCCESS,
          payload: "error",
        });
      });
  };
};
export const addNote = (jsonObj, callback) => {
  return async (dispatch) => {
    axios({
      method: "post",
      url: `${baseUrl}/note/add`,
      headers: await getHeaders(),
      data: jsonObj,
    })
      .then((response) => {
        callback(response.data.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const getMembersWithTracker = (user_id) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/member/getbyuser/${user_id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        dispatch({
          type: types.GET_ALL_TRACKERS_INVITED_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ALL_TRACKERS_INVITED_REJECTED,
          payload: "error",
        });
      });
  };
};

export const deleteGroup = (id, callback) => {
  return async (dispatch) => {
    axios({
      method: "PATCH",
      url: `${baseUrl}/group/delete/${id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const editGroup = (id, jsonObj, callback) => {
  return async (dispatch) => {
    axios({
      method: "PUT",
      url: `${baseUrl}/group/edit/${id}`,
      headers: await getHeaders(),
      data: jsonObj,
    })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};

export const deleteGroupMember = (user_id, group_id, callback) => {
  return async (dispatch) => {
    axios({
      method: "PATCH",
      url: `${baseUrl}/member/delete/${user_id}/${group_id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        callback("error");
      });
  };
};


export const fetchMyApprovedGroup = (user_id, callback) => {
  return async (dispatch) => {
    axios({
      method: "GET",
      url: `${baseUrl}/my/approved/group/${user_id}`,
      headers: await getHeaders(),
    })
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        callback([])
      });
  };
};